import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Alert, Table, Card, Form, InputGroup, Button} from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import BootstrapModal from '../../../Misc/BootstrapModal.js';
import {withSelectedResourceContext} from '../AccountSearch/SelectedResourceProvider.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountNewOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            requestMap: {},
            organizations: [],
            selectedOrganization: null,
            clientEmployees: [],
            recipients: [],
            resources: new Map(),
            attachments: [],
            subject: null,
            message: null,
            organization: null,
            showAdditionalResourceModal: false,
            isLoading: true
        };

        this.getOrganizations = this.getOrganizations.bind(this);
        this.getRequests = this.getRequests.bind(this);
        this.getOfferEmail = this.getOfferEmail.bind(this);
        this.getResources = this.getResources.bind(this);
        this.getClientEmployees = this.getClientEmployees.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRatesChange = this.handleRatesChange.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.sendOffer = this.sendOffer.bind(this);
        this.submitAddResources = this.submitAddResources.bind(this);
    }

    componentDidMount() {
        this.getOrganizations();
        this.getRequests();
        this.getResources();
    }

    render() {
        let resources = [];
        let attachments = [];
        let previousOffers = "";

        if (this.state.previousOffers != null && this.state.previousOffers.length > 0) {
            let prevOff = this.state.previousOffers.map(function(po, key) {
                return (
                    <p key={key}>
                        You have previously provided resource <strong>{po.user.displayName}</strong> to
                        client <strong>{po.organization.legalName}</strong> on <strong>{po.createdDate}</strong> for rate <strong>€{po.supremeHourlyRate}</strong>
                    </p>
                );
            }, this)


            previousOffers = <Card.Title>
                    <Alert variant="warning">
                        <Alert.Heading>WARNING!</Alert.Heading>
                        {prevOff}
                    </Alert>
                </Card.Title>;
        }

        if (this.props.selectedResourcesContext.state.selectedResources && this.props.selectedResourcesContext.state.selectedResources.length) {
            resources = this.props.selectedResourcesContext.state.selectedResources.map(function(resource, key) {
                return (
                    <tr key={key}>
                        <td>{resource.properties.name}</td>
                        <td>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                <Form.Control name="contractorHourlyRate" type="number" placeholder="Contractor Hourly Rate" value={this.state.resources.get
                                (resource.properties.code) ? this.state.resources.get(resource.properties.code).get("contractorHourlyRate") : resource.properties.contractorHourlyRate ? resource.properties.contractorHourlyRate.amount : ""}                         required
                                onChange={this.handleRatesChange} data-id={resource.properties.code} step={0.01} min={0} max={100}/>
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                <Form.Control name="supremeHourlyRate" type="number" placeholder="Supreme Rate" value={this.state.resources.get(resource.properties
                                .code) ?  this.state.resources.get(resource.properties.code).get("supremeHourlyRate") : ""} data-id={resource.properties.code}
                                 required onChange={this.handleRatesChange} step={0.01} min={0} max={100}/>
                           </InputGroup>
                       </td>
                    </tr>);
            }, this);
        } else {
          resources = <tr><td className="text-center" colSpan="3">No resources selected</td></tr>;
        }

        if (this.state.attachments && this.state.attachments.length) {
            attachments = this.state.attachments.map(function(attachment, key) {
                return (
                    <tr key={key}>
                        <td>{attachment}</td>
                    </tr>);
            }, this);
        } else {
          attachments = <tr><td className="text-center">You have no attachments</td></tr>;
        }

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                {previousOffers}
                                <Col sm={12} md={12} className="mt-3 mt-md-0">
                                    <Card.Title className="text-uppercase fw-bold">
                                        Send New Offer
                                    </Card.Title>
                                    <p>Please create your new offer</p>
                                    <Form id="offerForm" onSubmit={this.sendOffer}>
                                        <Row>
                                            <Col md={12} lg={12}>
                                                 <Form.Group as={Row} controlId="request" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Request
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Select name="request" options={this.state.requests} value={this.state.request}
                                                            closeMenuOnSelect={true} className="w-100 react-select-container" classNamePrefix="react-select"
                                                            onChange={(event)=> {this.redirectToRequest(event, this.props.history)}}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                 <Form.Group as={Row} controlId="organization" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Organization
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Select name="organization" options={this.state.organizations} value={this.state.organization}
                                                            closeMenuOnSelect={true} className="w-100 react-select-container" required classNamePrefix="react-select"
                                                            onChange={(event)=> {this.redirectToOrganization(event, this.props.history)}}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="recipients" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Recipients*
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <CreatableSelect name="recipients" options={this.state.clientEmployees} value={this.state.recipients}
                                                            closeMenuOnSelect={false} isMulti className="w-100 react-select-container" required classNamePrefix="react-select"
                                                            onChange={this.handleInputChange}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                 <Form.Group as={Row} controlId="subject" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Subject*
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Form.Control name="subject" type="text" placeholder="Subject" value={this.state.subject || ""}
                                                            required
                                                             onChange={this.handleInputChange} />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="message" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Message*
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Form.Control name="message" as="textarea" placeholder="Message" value={this.state.message || ""}
                                                            onChange={this.handleInputChange} rows={10} />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="attachments" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Attachments
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Attachments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {attachments}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr><th><Button variant="secondary" type="button">Add more</Button></th></tr>
                                                            </tfoot>
                                                        </Table>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="resources" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Resources*
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <Table striped bordered hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Contractor Rate</th>
                                                                    <th>Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {resources}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th colSpan={3}><Button variant="secondary" onClick={(e)=>this.setState({showAdditionalResourceModal:true})}>Add more</Button></th>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <Button variant="primary" type="submit" className="float-end text-uppercase" disabled={this.state.isLoading}>Send</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <BootstrapModal name="Add resources" show={this.state.showAdditionalResourceModal} handleClose={()=>{this.setState({showAdditionalResourceModal:false})}} handleAction={this.submitAddResources} size="l" actionBtn="Add resources">
                                    <Form>
                                        <Form.Group as={Row} controlId="message" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Name*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="externalName" placeholder="Name" value={this.state.externalName || ""} onChange={this.handleInputChange} />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="message" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Initials*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="externalInitials" placeholder="Message" value={this.state.externalInitials || ""} onChange={this.handleInputChange} />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="cv" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                CV*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="externalCV" type="file" onChange={this.handleInputChange} multiple={true}/>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </BootstrapModal>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;

                const name = target.name;
                this.handleChangeForm(name, value);
                if (target.type==='file') {
                    this.setState({externalCVFile: target.files});
                }
            } else {
                let newRecipients = [];
                event.forEach(sel => {
                    newRecipients.push({value:sel.value, label:sel.label});
                });
                this.handleChangeForm("recipients", newRecipients);
            }
        }
    }

    getOrganizations() {
        ApiCall.get('client-organization/names').then((response) => {
            let organizations = [];
            for (const [key, value] of Object.entries(response.data)) {
                organizations.push({value: `${key}`, label: `${value}`});
            }
            this.setState({
                organizations: organizations,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    submitAddResources() {
        this.props.progressBarContext.dispatch({type: 'increment', progress: 20});

        let formData = new FormData();
        formData.append("name", this.state.externalName);
        formData.append("initials", this.state.externalInitials);
        if(this.state.request) {
            formData.append("jobRequestCode", this.state.request.value);
        }
        formData.append("file", this.state.externalCVFile[0]);

        ApiCall.post('request/additional-resource', formData, 'multipart/form-data', null).then((response) => {

            //append in state.resources new map entry: code-Map

            let entry = new Map();
            entry.set("contractorHourlyRate", 0);
            entry.set("supremeHourlyRate", 0);
            let currentResources = this.state.resources;
            currentResources.set(response.data, entry);
            this.props.selectedResourcesContext.selectResource({properties : {code: response.data, initials: this.state.externalInitials, name: this.state.externalName}});
            this.setState({showAdditionalResourceModal:false, externalCV: "", externalCVFile: null, externalInitials:"", externalName:"", resources: currentResources});
            UserHelper.showSuccessToast("Additional Resources Successfully Created!");
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getRequests() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.get('request').then((response) => {
            let requests = [];
            let requestMap = {};

            for (const req of response.data) {
                requests.push({value: `${req.code}`, label: `${req.name} (${req.organization ? req.organization.legalName: ''})`});
                requestMap[`${req.code}`] = req;
            }
            this.setState({
                requests: requests,
                requestMap: requestMap,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    redirectToRequest(event, history) {
        let selectedOrganization = this.state.requestMap[`${event.value}`].organization.code;
        this.setState({request:event, organization: selectedOrganization});
        this.getClientEmployees(selectedOrganization);
        this.getOfferEmail(selectedOrganization);
    }

    redirectToOrganization(event, history) {
        if (!this.state.request) {
            this.setState({organization:event});
            this.getClientEmployees(event.value);
            this.getOfferEmail(event.value);
        }
    }

    getOfferEmail(organizationCode) {

        let contractors = [];
        if (this.props.selectedResourcesContext.state.selectedResources) {
            contractors = this.props.selectedResourcesContext.state.selectedResources.map(function(elem){  return elem.properties.code;}).join(",");
        }

        ApiCall.get('offer/email/' + organizationCode, {contractors : contractors}).then((response) => {

            let recipients = [];
            response.data.recipients.forEach(r => {
                recipients.push({value: r.address, label: r.personal});
            });

            let attachments = [];
            for (var m in response.data.attachments) {
                attachments.push(m);
            }

            let subject = response.data.subject;
            if (this.state.request) {
                subject += " - " + this.state.requestMap[this.state.request.value].name;
            }

            this.setState({
                subject: subject,
                message: response.data.message,
                recipients: recipients,
                previousOffers: response.data.offers,
                organization: {value:organizationCode, label: response.data.organizationLegalName},
                //attachments: attachments,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getClientEmployees(code) {
        let url = 'supreme-customer/clientgroup';
        if (code) {
            url = 'businessCustomer/forOrganization/' + code;
        }

        ApiCall.get(url, {
            projection: "supreme.contractors.backend.facade.dto.BasicNameableDtoDefinition",
            size: 1000
        }).then((response) => {
            let clientEmployees = [];
            response.data.content.forEach(r => {
                clientEmployees.push({value: r.code, label: r.displayName});
            });

            this.setState({
                clientEmployees: clientEmployees,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getResources() {
        let resources = new Map();
        if (this.props.selectedResourcesContext.state.selectedResources) {
            this.props.selectedResourcesContext.state.selectedResources.forEach(resource=>{
                let entry = new Map();
                entry.set("contractorHourlyRate", resource.properties.contractorHourlyRate ? resource.properties.contractorHourlyRate.amount: 0);
                entry.set("supremeHourlyRate", resource.properties.contractorHourlyRate ? ((resource.properties.contractorHourlyRate.amount * 8) + 100)/8 : 0);
                resources.set(resource.properties.code, entry);
            });
        }

        this.setState({resources:resources})
    }

    handleChangeForm(field, value) {
        this.setState({
            [field]: value
        });
    }

    handleRatesChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.value;
                const name = target.name;
                const code = target.dataset.id;
                let res = this.state.resources;
                let entry = this.state.resources.get(code);
                entry.set(name, value);
                res.set(code, entry);
                this.setState({resources:res});
                //this.handleChangeForm(name, value);
            }
        }

    }

    sendOffer(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        event.preventDefault();
        this.setState({isLoading:true});

        let offers = [];

        for (let [key, value] of this.state.resources) {
            if (this.state.request) {

                //if external create external user.

                offers.push(
                    {
                        user: {
                            code:key
                        },
                        jobRequest: {
                            "@class": "supreme.contractors.backend.facade.dto.JobRequestDto",
                            code: this.state.request.value
                        },
                        supremeHourlyRate: value.get("supremeHourlyRate"),
                        contractorHourlyRate: value.get("contractorHourlyRate")
                    }
                );
            } else {
                offers.push(
                    {
                        user: {
                            code:key
                        },
                        organization: {
                            "@class": "io.nemesis.platform.facade.location.dto.OrganizationDto",
                            code: this.state.organization.value
                        },
                        supremeHourlyRate: value.get("supremeHourlyRate"),
                        contractorHourlyRate: value.get("contractorHourlyRate")
                    }
                );
            }
        }

        let recipients = [];
        this.state.recipients.forEach(recipient => {
            recipients.push({address:recipient.value, personal:recipient.label});
        });


        ApiCall.post('offer/send', {
            subject: this.state.subject,
            message: this.state.message,
            recipients: recipients,
            //attachments: this.state.attachments,
            offers: offers
        }).then(() => {
            this.props.selectedResourcesContext.clearSelectedResources();
            this.props.history.push({pathname:"/my-account/offers",state: { globalInfoMsgs: ["Offer has been sent"] }});
            this.setState({isLoading:false});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });

    }

}

export default withProgressBarContext(withRouter(withSelectedResourceContext(AccountNewOffer)));
