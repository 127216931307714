import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Card, InputGroup, Form, Dropdown, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import qs from 'qs';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import OrderSettingsModal from './OrderSettingsModal.js';
import BootstrapTable from '../../../Misc/BootstrapTable.js';
import AccountConfirmModal from './AccountConfirmModal.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

import './AccountOrderHistory.scss';

class AccountOrderHistorySales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMonth:null,
            organizations:null,
            statuses:[{value:'EMAIL_SENT', label: 'EMAIL_SENT'}, {value:'ACCEPTED', label: 'ACCEPTED'}, {value:'REJECTED', label: 'REJECTED'}, {value: 'PAID', label: 'PAID'},{value:'CREATED', label:'CREATED'}],
            outgoingOrdersPage: {},
            incomingOrdersPage: {},
            isOutgoingLoading: true,
            isIncomingLoading: true,
            showOrderSettingsModal: false,
            deleteConfirmModal: null
        };

        this.getOrganizations = this.getOrganizations.bind(this);
        this.getIncomingOrders = this.getIncomingOrders.bind(this);
        this.getOutgoingOrders = this.getOutgoingOrders.bind(this);
        this.generateOutgoingOrders = this.generateOutgoingOrders.bind(this);
        this.updateOrderStatus = this.updateOrderStatus.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.getOrderStatuses = this.getOrderStatuses.bind(this);
        this.getOrderSettings = this.getOrderSettings.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.validateInvoice = this.validateInvoice.bind(this);
        this.changeFrozenStatus = this.changeFrozenStatus.bind(this);
        this.redirectToMonth = this.redirectToMonth.bind(this);
        this.getTrColor = this.getTrColor.bind(this);
        this.getActionsFormatForIncoming = this.getActionsFormatForIncoming.bind(this);
        this.getActionsFormatForOutgoing = this.getActionsFormatForOutgoing.bind(this);
        this.getStatusForIncoming = this.getStatusForIncoming.bind(this);
        this.downloadAllInvoices = this.downloadAllInvoices.bind(this);
        this.handleOrderSettingsModalClose = this.handleOrderSettingsModalClose.bind(this);
        this.handleOrderSettingsModalShow = this.handleOrderSettingsModalShow.bind(this);
    }

    componentDidMount() {
        let isAccountant = UserHelper.isAccountant();
        this.getOrderStatuses();
        this.getOrganizations();

        if (!isAccountant) {
            this.getOrderSettings();
        }
        let selectedMonth = null;
        if (this.props.location && this.props.location.hash) {
            selectedMonth = this.props.location.hash.substring(1);
            this.setState({selectedMonth : selectedMonth});
        }
        let selectedOrganization, selectedStatus = null;

        if (this.props.location && this.props.location.search) {
            let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            selectedOrganization = queryParams.selectedOrganization && queryParams.selectedOrganization !== '' ? queryParams.selectedOrganization : null;
            if (selectedOrganization) {
                 this.setState({selectedOrganization : {value:selectedOrganization, label: selectedOrganization}});
            }
            selectedStatus = queryParams.selectedStatus && queryParams.selectedStatus !== '' ? queryParams.selectedStatus : null;
            if (selectedStatus) {
                this.setState({selectedStatus: {value:selectedStatus, label: selectedStatus}});
            }
        }

        this.getOutgoingOrders(0, selectedMonth, selectedOrganization, selectedStatus, true);
        this.getIncomingOrders(0, true);
    }

    render() {
        let isAccountant = UserHelper.isAccountant();
        const outgoingColumns = [
            {
              dataField: 'code',
              text: 'Order number',
              formatter: (cell, row) => <a href={"/my-account/order-view#" + cell}>{ cell }</a>,
              footer: columnData => <PaginationInfo page={this.state.outgoingOrdersPage} />,
              footerAttrs: {
                 colSpan: 2
              }
            },
            {
              dataField: 'recipientLegalName',
              text: 'Client'
            },
            {
              dataField: 'created',
              text: 'Date Placed',
              footer: ''
            },
            {
              dataField: 'dummy1',
              formatter: this.getStatusForIncoming,
              text: 'Order Status'
            },
            {
              dataField: 'totalAmount.formatted',
              text: 'Total'
            },
            {
               dataField: 'dummy2',
               formatter: this.getActionsFormatForOutgoing,
               text: 'Actions',
               footer: columnData => <Pagination page={this.state.outgoingOrdersPage} handleChangePage={this.getOutgoingOrders} isLoading={this.state.isOutgoingLoading} />,
               footerAttrs: {
                  colSpan: 3
               }
            }
        ];

        const incomingColumns = [
            {
              dataField: 'code',
              text: 'Order number',
              formatter: (cell, row) => <a href={"/my-account/order-view#" + cell}>{ cell }</a>,
              footer: columnData => <PaginationInfo page={this.state.incomingOrdersPage} />,
              footerAttrs: {
                  colSpan: 2
              }
            },
            {
              dataField: 'issuerLegalName',
              text: 'Issuer'
            },
            {
              dataField: 'created',
              text: 'Date Placed',
              footer: ''
            },
            {
              dataField: 'dummy1',
              formatter: this.getStatusForIncoming,
              text: 'Order Status'
            },
            {
              dataField: 'totalAmount.formatted',
              text: 'Total'
            },
            {
               dataField: 'dummy2',
               formatter: this.getActionsFormatForIncoming,
               text: 'Actions',
               footer: columnData => <Pagination page={this.state.incomingOrdersPage} handleChangePage={this.getIncomingOrders} isLoading={this.state.isIncomingLoading} />,
               footerAttrs: {
                  colSpan: 3
               }
            }
        ];

        const expandRow = {
          renderer: row => {
            let entries = row.cart.entries.map(function(entry, key) {
                return <div key={entry.code} id={entry.entity.code}>
                        <span className="ms-5">{entry.entity.name}</span>
                        <span className="float-end me-5">
                            {entry.quantity} {entry.unit.code} x {entry.baseAmount.formatted} = <span className="fw-bold">{entry.totalAmount.formatted}</span>
                        </span>
                 </div>;
            });

            return (
            <div>
                {entries}
                {row.rejectReason ?
                    <Alert variant="danger" className="mt-3">
                        <div className="text-center">
                            <FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{row.rejectReason}
                        </div>
                    </Alert>
                :""}
            </div>
          )},
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: (isAnyExpands) => {
              if (isAnyExpands) {
                return <b>[-]</b>;
              }
              return <b>[+]</b>;
          },
          expandColumnRenderer: (expanded) => {
              if (expanded) {
                return (
                  <b>[-]</b>
                );
              }
              return (
                <b>[+]</b>
              );
          }
        };

        let skin = UserHelper.isDark() ? "table-dark" : "";

        return (
        <>
            <Row className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body className="order-history-wrapper">
                            <Card.Title className="text-uppercase fw-bold">
                                Outgoing Orders
                                <Dropdown className="float-end ms-2 text-bf">
                                  <Dropdown.Toggle variant="primary">
                                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.downloadAllInvoices()}>Download</Dropdown.Item>
                                    {!isAccountant ?
                                        <>
                                            <Dropdown.Item onClick={() => this.generateOutgoingOrders()}>Generate</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {this.props.history.push('/my-account/order/new')}}>New Order</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {this.props.history.push('/my-account/credit-note/new')}}>New Credit Note</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {this.props.history.push('/my-account/debit-note/new')}}>New Debit Note</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.handleOrderSettingsModalShow()}>Order Settings</Dropdown.Item>
                                        </>
                                    :null}
                                  </Dropdown.Menu>
                                </Dropdown>
                                <OrderSettingsModal show={this.state.showOrderSettingsModal} handleOnOrderSettingsModalClose={this
                                .handleOrderSettingsModalClose} orderSettings={this.state.orderSettings}/>
                            </Card.Title>
                            <p>This is a list of your outgoing orders</p>
                            <Row className="mb-3">
                                <Col md={4} lg={4}>
                                    <Form.Group controlId="date" className="mb-3">
                                        <InputGroup className="mb-3">
                                            <Form.Control name="createdDate" type="month" clearable="true" placeholder="Month" defaultValue={this.state
                                            .selectedMonth || ""}
                                            onChange={(event)=> {this.redirectToMonth(event, this.props.history)}}/>
                                            {this.state.selectedMonth ?
                                                <a href="/my-account/orders" className="pt-2 ps-2"><FontAwesomeIcon icon={"xmark"}/></a>
                                            :""}
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4} lg={4}>
                                    <Form.Group controlId="client">
                                        <InputGroup className="mb-3">
                                            <Select name="organization" options={this.state.organizations} value={this.state.selectedOrganization}
                                                closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                                onChange={(event)=> {this.redirectToOrganization(event, this.props.history)}}/>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={4} lg={4}>
                                    <Form.Group controlId="status">
                                        <InputGroup className="mb-3">
                                            <Select name="status" options={this.state.statuses} value={this.state.selectedStatus}
                                                closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                                onChange={(event)=> {this.redirectToStatus(event, this.props.history)}}/>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <BootstrapTable
                              keyField='code'
                              classes={"table-align-middle " + skin}
                              data={ this.state.outgoingOrdersPage.content }
                              noDataIndication="No Outgoing Orders"
                              columns={ outgoingColumns }
                              expandRow={ expandRow }
                              bordered={true}
                              hover
                              condensed
                              rowClasses={ this.getTrColor }
                              isLoading={this.state.isOutgoingLoading}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body className="order-history-wrapper">
                            <Card.Title className="text-uppercase fw-bold">
                                Incoming Orders
                            </Card.Title>
                            <p>This is a list of your incoming orders</p>
                            <BootstrapTable
                              keyField='code'
                              classes={"table-align-middle " + skin}
                              data={ this.state.incomingOrdersPage.content }
                              noDataIndication="No Incoming Orders"
                              columns={ incomingColumns }
                              expandRow={ expandRow }
                              bordered={true}
                              hover
                              condensed
                              rowClasses={ this.getTrColor }
                              isLoading={this.state.isIncomingLoading}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <AccountConfirmModal show={this.state.deleteConfirmModal !== null}
                                 handleModalClose={()=>this.setState({deleteConfirmModal:null})}
                                 header="Delete order?"
                                 body="Are you sure you want to delete this order?"
                                 performAction={() => {this.deleteOrder(this.state.deleteConfirmModal);this.setState({deleteConfirmModal:null})}}/>
        </>
        )
    }

    getOutgoingOrders(pageNumber, month, clientCode, status, updateLoadingStatus) {
        this.setState({
            isOutgoingLoading: true && updateLoadingStatus
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let defaultSize = queryParams.outgoingSize ? queryParams.outgoingSize : 20;

        let params = new Map();
        params.set('page', pageNumber);
        params.set('size', defaultSize);
        if (month) {
            params.set('month', month);
        }
        if (clientCode) {
            params.set('clientCode', clientCode);
        }
        if (status) {
            params.set('status', status);
        }
        ApiCall.get('supremeOrder/outgoing', Object.fromEntries(params)).then((response) => {
            this.setState({
                outgoingOrdersPage: response.data,
                isOutgoingLoading: false
            });
            if (updateLoadingStatus) {
                this.props.progressBarContext.dispatch({type: 'update', progress: 20});
            }
        }).catch(error => {
            console.log(error);

            this.setState({
                outgoingOrdersPage: [],
                isOutgoingLoading: false
            });
        });
    }

    generateOutgoingOrders() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('supremeOrder/generate', {},{}).then((response) => {
            this.getOutgoingOrders(this.state.outgoingOrdersPage.number, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, true);
            UserHelper.showSuccessToast('Orders were generated');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isOutgoingLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getIncomingOrders(pageNumber, updateLoadingStatus) {
        this.setState({
            isIncomingLoading: true && updateLoadingStatus
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let defaultSize = queryParams.incomingSize ? queryParams.incomingSize : 10;

        ApiCall.get('supremeOrder/incoming', {
            page: pageNumber,
            size: defaultSize
        }).then((response) => {
            this.setState({
                incomingOrdersPage: response.data,
                isIncomingLoading: false
            });
            if (updateLoadingStatus) {
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            }
        }).catch(error => {
            console.log(error);

            this.setState({
                incomingOrdersPage: [],
                isIncomingLoading: false
            });
        });
    }

    changeFrozenStatus = (orderCode, status) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let url = "supremeOrder/freeze/";
        if (orderCode) {
            url+=orderCode;
        }

        ApiCall.post(url, {'status' : status}).then((response) => {
            this.getOutgoingOrders(this.state.outgoingOrdersPage.number, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, false);
            let msg = 'Order was unfrozen!';
            if (status) {
                msg = "Order was frozen!";
            }
            UserHelper.showSuccessToast(msg);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isOutgoingLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    downloadInvoice = (orderCode) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        fetch(process.env.REACT_APP_FACADE_URL + 'invoice/' + orderCode, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = orderCode + '.pdf';
                    a.click();
                });
                this.setState({downloadStarted:false});
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    validateInvoice = (orderCode) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let url = "supremeOrder/validate/"+orderCode;
        ApiCall.post(url).then((response) => {

            let valid = true;

            for (var assignment of response.data) {
                if (assignment.status === 'UNKNOWN') {
                    UserHelper.showWarningToast(assignment.assignment.user.displayName + " working for client '" + assignment.assignment.manager.department.organization.legalName + "' has unknown status");
                    valid = false;
                } else if (assignment.status === 'ERROR') {
                    UserHelper.showErrorToast(assignment.assignment.user.displayName + " working for client '" + assignment.assignment.manager.department.organization.legalName + "' reported wrong hours! Reported: " + assignment.reported + " actual:" + assignment.actual);
                    let d = document.getElementById(assignment.assignment.code);
                    if (d) {
                        d.className += " text-danger";
                    }
                    valid = false;
                }
            }
            if (valid) {
                UserHelper.showSuccessToast("All assignments in this order are valid!");
            }
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isOutgoingLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getActionsFormatForIncoming(cell, order) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };
        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => this.downloadInvoice(order.code)}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.history.push('/my-account/order-view#' + order.code)}>View</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.validateInvoice(order.code)}>Validate</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getActionsFormatForOutgoing(cell, order) {
        let isSales = UserHelper.isSales();
        let isAccountant = UserHelper.isAccountant();
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };

        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    {!isAccountant && !order.frozen ?
                        <Dropdown.Item onClick={() => this.props.history.push('/my-account/order#' + order.code)}>Edit</Dropdown.Item>
                    :null}
                    <Dropdown.Item onClick={() => this.downloadInvoice(order.code)}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.history.push('/my-account/order-view#' + order.code)}>View</Dropdown.Item>
                    {(isAccountant || isSales) ?
                        <Dropdown.Item onClick={() => this.changeFrozenStatus(order.code, !order.frozen)}>{!order.frozen?"Freeze":"Unfreeze"}</Dropdown.Item>
                    :null}
                    {!isAccountant && (!order.orderType || order.orderType === 'ORDER') ?
                        <Dropdown.Item onClick={() => this.sendEmail(order.code)}>Email</Dropdown.Item>
                    :null}
                    {!isAccountant && !order.frozen ?
                        <Dropdown.Item onClick={()=>this.setState({deleteConfirmModal:order.code})}>Delete</Dropdown.Item>
                    :null}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getStatusForIncoming(cell, order) {
        let isAccountant = UserHelper.isAccountant();
        if (isAccountant) {
            return order.status;
        }

        let orderStatuses = [];
        if (this.state.orderStatuses) {
            orderStatuses = this.state.orderStatuses.map(function(status, key){
                return <option key={key} value={status}>{status}</option>;
            });
        }

        return (
            <Form.Select name="status" data-id={order.code} value={order.status} required onChange={this.updateOrderStatus}>
                { orderStatuses }
            </Form.Select>
        );
    }


    updateOrderStatus(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        if (event) {
            let status = event.target.value;
            ApiCall.put('supremeOrder/orderStatus/' + event.target.dataset.id, {},{'orderStatus':status}).then((response) => {
                this.getIncomingOrders(this.state.incomingOrdersPage.number, false);
                this.getOutgoingOrders(this.state.outgoingOrdersPage.number, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, false);
                UserHelper.showSuccessToast('Order status was updated');
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                this.setState({
                    isIncomingLoading: false
                });
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            });
        }
    }

    deleteOrder(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.delete('supremeOrder/' + code).then((response) => {
            UserHelper.showSuccessToast("The order was deleted.");
            this.getOutgoingOrders(this.state.outgoingOrdersPage.number, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, false);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                profileInfoMsg: null
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    sendEmail(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('supremeOrder/email/' + code).then((response) => {
            UserHelper.showSuccessToast("Email was sent.");
            this.getOutgoingOrders(this.state.outgoingOrdersPage.number, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, false);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                profileInfoMsg: null
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    redirectToMonth(event, history) {
        this.setState({
            selectedMonth: event.target.value
        });

        this.getOutgoingOrders(0, event.target.value, this.state.selectedOrganization, event.target.value, this.state.selectedStatus, true);

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let searchParams = {
            selectedMonth: event ? event.target.value : null,
            selectedStatus: queryParams.selectedStatus ? queryParams.selectedStatus : null,
            selectedOrganization: queryParams.selectedOrganization ? queryParams.selectedOrganization : null,
        };
        this.props.history.replace({
            search: '?' + qs.stringify(searchParams)
        });
    }

    redirectToOrganization(event, history) {
        this.setState({
            selectedOrganization: event
        });

        this.getOutgoingOrders(0, this.state.selectedMonth, event ? event.value : null, this.state.selectedStatus, true);

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let searchParams = {
            selectedMonth: queryParams.selectedMonth ? queryParams.selectedMonth : null,
            selectedStatus: queryParams.selectedStatus ? queryParams.selectedStatus : null,
            selectedOrganization: event ? event.value : null
        };
        this.props.history.replace({
            search: '?' + qs.stringify(searchParams)
        });
    }

    redirectToStatus(event, history) {

        this.setState({
            selectedStatus: event
        });

        this.getOutgoingOrders(0, this.state.selectedMonth, this.state.selectedOrganization, event ? event.value : event, true);

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let searchParams = {
            selectedMonth: queryParams.selectedMonth ? queryParams.selectedMonth : null,
            selectedOrganization: queryParams.selectedOrganization ? queryParams.selectedOrganization : null,
            selectedStatus: event ? event.value : null
        };

        this.props.history.replace({
            search: '?' + qs.stringify(searchParams)
        });
    }


    getOrderStatuses() {
        ApiCall.get('supremeCart/orderStatuses').then((response) => {
            this.setState({ orderStatuses: response.data });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getOrderSettings() {
        ApiCall.get("supremeOrder/settings").then((response) => {
            this.setState({orderSettings : response.data});
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred getting order settings. Please try again later.');
        });
    }

    getTrColor(order, rowIndex) {
        let result = order.frozen ? "disabled " : "";
        if (order.status === 'CREATED') {
            return result += "table-primary";
        } else if (order.status === 'REJECTED') {
            return result + 'table-danger';
        } else if (order.status === 'ACCEPTED') {
            return result + "table-info";
        } else if (order.status === 'EMAIL_SENT') {
            return result + "table-warning";
        } else if (order.status === 'PAID') {
            return result + "table-success";
        } else if (order.status === 'CANCELLED') {
            return result + "table-muted";
        }
    }

    downloadAllInvoices() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        this.setState({downloadStarted:true});
        let url = process.env.REACT_APP_FACADE_URL + 'supremeOrder/download';
        if (this.state.selectedMonth) {
            url += "?month=" + this.state.selectedMonth;
        }

        fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/zip",
              "Content-Type": "application/zip",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'invoices.zip';
                    a.click();
                });
                this.setState({downloadStarted:false});
                this.getOutgoingOrders(0, this.state.selectedMonth, this.state.selectedOrganization, this.state.selectedStatus, false);
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    handleOrderSettingsModalClose() {
        this.setState({
            showOrderSettingsModal: false
        });
        this.getOrderSettings();
    }

    handleOrderSettingsModalShow() {
        this.setState({
            showOrderSettingsModal: true
        });
    }

    getOrganizations() {
        ApiCall.get('client-organization/names').then((response) => {
            let organizations = [];
            for (const [key, value] of Object.entries(response.data)) {
                organizations.push({value: `${key}`, label: `${value}`});
            }
            this.setState({
                organizations: organizations,
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }
}

export default withRouter(withProgressBarContext(AccountOrderHistorySales));
