import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Container, Navbar, Nav} from 'react-bootstrap';
import UserHelper from '../../../components/Helper/UserHelper.js';

import './AccountNav.scss';

export default class AccountNav extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false
        };
    }

    render() {

        let isAccountant = UserHelper.isAccountant();
        let isContractor = UserHelper.isContractor();
        let isManager = UserHelper.isManager();
        let isIndividualContractor = UserHelper.isIndividualContractor();
        let isSales = UserHelper.isSales();
        let isSuperSales = UserHelper.isSuperSales();
        let isClient = UserHelper.isClient();

        return (
            <Navbar expand="lg" className="user-nav rounded border" expanded={this.state.open} onToggle={(e) => this.setState({ open : e })}>
                <Navbar.Toggle aria-controls="top-navbar-nav" className="ms-2"/>
                <Navbar.Collapse id="top-navbar-nav">
                    <Container>
                        <Nav className="nav-fill w-100">
                            <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "profile" ? "active" : ""}`} as={Link} to="/my-account/profile" onClick={(e) => this.setState({ open: false })}>Profile</Nav.Link>
                            {isIndividualContractor || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "legal" ? "active" : ""}`} as={Link} to="/my-account/legal" onClick={(e) => this.setState({ open: false })}>Legal</Nav.Link>
                                </>
                            : null}
                            {isSuperSales ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "clients" || this.props.selected === "client" ? "active" : ""}`} as={Link} to="/my-account/clients" onClick={(e) => this.setState({ open: false })}>Clients</Nav.Link>
                            </>
                            :null}
                            {isContractor ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "experience" ? "active": ""}`} as={Link} to="/my-account/experience" onClick={(e) => this.setState({ open: false })}>Experience</Nav.Link>
                            </>
                            : null}
                            {isIndividualContractor ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "rates" ? "active" : ""}`} as={Link} to="/my-account/rates" onClick={(e) => this.setState({ open: false })}>Rates</Nav.Link>
                                </>
                            : null}
                            {isManager || isSales || isSuperSales || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "requests" || this.props
                                .selected === "request" ? "active" : ""}`} as={Link} to="/my-account/requests" onClick={(e) => this.setState({ open: false })}>Requests</Nav.Link>
                            :null}
                            {isSales || isManager ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "search" ? "active" : ""}`} as={Link} to="/my-account/search" onClick={(e) => this.setState({ open: false })}>Search</Nav.Link>
                                </>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "offers" || this.props.selected ==="offer" ? "active" : ""}`} as={Link} to="/my-account/offers" onClick={(e) => this.setState({ open: false })}>Offers</Nav.Link>
                                </>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "interviews" ? "active"
                                    : ""}`} as={Link} to="/my-account/interviews" onClick={(e) => this.setState({ open: false })}>Interviews</Nav.Link>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "assignments" ? "active"
                                    : ""}`} as={Link} to="/my-account/assignments" onClick={(e) => this.setState({ open: false })}>Assignments</Nav.Link>
                                </>
                            :''}
                            {isIndividualContractor || isSuperSales || isAccountant || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "orders"  || this.props.selected ==="order" || this.props.selected ==="order-view" ? "active"
                                : ""}`} as={Link} to="/my-account/orders" onClick={(e) => this.setState({ open: false })}>Orders</Nav.Link>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "vacation" ? "active"
                                : ""}`} as={Link} to="/my-account/vacation" onClick={(e) => this.setState({ open: false })}>Vacation</Nav.Link>
                            : null}
                            {isSuperSales ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "reports" ? "active": ""}`} as={Link} to="/my-account/reports" onClick={(e) => this.setState({ open: false })}>Reports</Nav.Link>
                            : null}
                            {isContractor ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "reviews" ? "active" : ""}`} as={Link} to="/my-account/reviews" onClick={(e) => this.setState({ open: false })}>Reviews</Nav.Link>
                            </>
                            : null}
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
